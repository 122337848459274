export const apiCode = {
  SUSCESS: 0,
  NEEDSIGNOUT: 502,
  UNAUTHORIZED: 500,
};

export const domain = {
  SPORTBOOK: "sportBook",
  AMBEXAPI: "ambexApi",
  DOMAIN_3: "domain3",
};

import React from "react";
import { Article10 } from "../components";

const ArticleScreen10 = () => {
    return (
        <>
            <Article10 />
        </>
    );
};

export default ArticleScreen10;

import { useState } from "react";
import ReactDOM from "react-dom/client";
import Api from "../../service/Api";
import ApiConfig from "../../service/ApiConfig";
import { apiCode } from "../../utils/constant";
import {
  useSetRecoilState,
  useRecoilValue,
  useRecoilState,
  atom,
} from "recoil";
import {
  gameListNoAuthState,
  gameListAuthState,
  gameListDataState,
  startGameState,
  getGameRedirect,
  urlRedirectState,
} from "../gameState";
import { mappingGameType } from "../../utils/gameType";
import { authState } from "../authState";
import { authModal } from "../../utils/authModal";
import { AuthenticationAction } from "./authenticationAction";

const dataSlotState = atom({
  key: "dataSlotState",
  default: "",
});

export { GameAction };

const GameAction = () => {
  const [dataSlot, setDataSlot] = useRecoilState(dataSlotState);
  const [dataCasino, setDataCasino] = useState("");
  const [dataKeno, setDataKeno] = useState("");
  const [dataCard, setDataCard] = useState("");
  const [dataSport, setDataSport] = useState("");
  const [dataLotto, setDataLotto] = useState("");
  const setGameListdata = useSetRecoilState(gameListDataState);
  const setStartGame = useSetRecoilState(startGameState);

  const api = Api();
  const setGameListNoAuth = useSetRecoilState(gameListNoAuthState);
  const setGameListAuth = useSetRecoilState(gameListAuthState);
  const auth = useRecoilValue(authState);
  const setUrlRedirect = useSetRecoilState(urlRedirectState);

  const authAction = AuthenticationAction();
  return {
    getGameListNoAuth,
    getGameListAuth,
    startGame,
    getGameRedirect,
  };

  async function getGameListNoAuth(type) {
    localStorage.type = type;
    let resGameListNoAuth, gameTypeData, gameListData;
    const favGame = JSON.parse(localStorage.getItem("favGame"));
    if (type == "Slot" && dataSlot) {
      gameListData = dataSlot;
    } else if (type == "Casino" && dataCasino) {
      gameListData = dataCasino;
    } else if (type == "Card" && dataCard) {
      gameListData = dataCard;
    } else if (type == "Sport" && dataSport) {
    } else if (type == "KENO" && dataKeno) {
      gameListData = dataKeno;
    } else if (type == "Lotto" && dataLotto) {
    } else if (type === "Favorite") {
      if (favGame === null) {
        gameListData = [];
      } else {
        gameListData = favGame;
      }
    } else if (type === "Hit") {
      var result = dataSlot.filter((item) => {
        return item.provider === "RECG";
      });
      gameListData = result;
    } else {
      resGameListNoAuth = await api.post(ApiConfig.APIs.post.gameListNoAuth, {
        type: type,
      });
      gameTypeData = resGameListNoAuth.data.data;
      // console.log(gameTypeData);
      if (type == "Slot") {
        setDataSlot(gameTypeData);
      } else if (type == "Casino") {
        setDataCasino(gameTypeData);
      } else if (type == "Card") {
        setDataCard(gameTypeData);
      } else if (type == "Sport") {
        setDataSport(gameTypeData);
      } else if (type == "Lotto") {
        setDataLotto(gameTypeData);
      }
      gameListData = gameTypeData;
    }
    setGameListNoAuth(gameListData);
  }

  async function getGameListAuth(type) {
    let resGameListAuth, gameTypeData, gameListData;
    if (type == "Slot" && dataSlot) {
      gameListData = dataSlot;
    } else if (type == "Casino" && dataCasino) {
      gameListData = dataCasino;
    } else if (type == "Card" && dataCard) {
      gameListData = dataCard;
    } else if (type == "Sport" && dataSport) {
    } else if (type == "Lotto" && dataLotto) {
    } else {
      resGameListAuth = await api.post(ApiConfig.APIs.post.gameListAuth, {
        type: type,
      });
      gameTypeData = resGameListAuth.data.data;
      if (type == "Slot") {
        setDataSlot(gameTypeData);
      } else if (type == "Casino") {
        setDataCasino(gameTypeData);
      } else if (type == "Card") {
        setDataCard(gameTypeData);
      } else if (type == "Sport") {
        setDataSport(gameTypeData);
      } else if (type == "Lotto") {
        setDataLotto(gameTypeData);
      }
      gameListData = gameTypeData;
    }
    setGameListdata(gameListData);
  }

  async function startGame(gameId, redirectUrl, language, provider, type) {
    const resStartGame = await api.post(ApiConfig.APIs.post.startGame, {
      playerUsername: auth.username,
      gameID: gameId,
      redirectUrl: redirectUrl,
      language: language,
      provider: provider,
      gameType: mappingGameType(type),
    });
    if (resStartGame.data.code === apiCode.SUSCESS) {
      setStartGame(resStartGame.data.data);
    } else if (resStartGame.data.code === apiCode.NEEDSIGNOUT) {
      authAction.logout(true);
    } else {
    }
  }

  async function getGameRedirect() {
    const resGetGameRedirect = await api.post(
      ApiConfig.APIs.post.getGameRedirect,
      {
        username: auth.username,
      }
    );
    if (resGetGameRedirect.data.code === apiCode.SUSCESS) {
      setUrlRedirect(resGetGameRedirect.data.url);
    } else {
    }
  }
};

import React from "react";
import "../../App.scss";
import "./style.scss";
import { Announcement } from "../../components";
import LazyLoad from "react-lazy-load";

const Promotion = () => {
  return (
    <div className="contentBox">
      <Announcement />
      <div className="container">
        <div className="promotionBox">
          <div className="promotionBox__header">
            <h2 className="title">โปรโมชัน</h2>
          </div>
          <div className="promotionBox__header">
            <div className="row">
              <div className="col-sm-12 col-lg-6 mt-3">
                <LazyLoad>
                  <img
                    className="promotion__image"
                    src={require("../../assets/images/promotion/promotion-6.jpg")}
                    alt="amb-promotion"
                  />
                </LazyLoad>
              </div>
              <div className="col-sm-12 col-lg-6 mt-3">
                <LazyLoad>
                  <img
                    className="promotion__image"
                    src={require("../../assets/images/promotion/promotion-7.jpg")}
                    alt="amb-promotion"
                  />
                </LazyLoad>
              </div>
              <div className="col-sm-12 col-lg-6 mt-3">
                <LazyLoad>
                  <img
                    className="promotion__image"
                    src={require("../../assets/images/promotion/promotion-8.jpg")}
                    alt="amb-promotion"
                  />
                </LazyLoad>
              </div>
              <div className="col-sm-12 col-lg-6 mt-3">
                <LazyLoad>
                  <img
                    className="promotion__image"
                    src={require("../../assets/images/promotion/promotion-5.jpg")}
                    alt="amb-promotion"
                  />
                </LazyLoad>
              </div>
              <div className="col-sm-12 col-lg-6 mt-3">
                <LazyLoad>
                  <img
                    className="promotion__image"
                    src={require("../../assets/images/promotion/promotion-2.jpg")}
                    alt="amb-promotion"
                  />
                </LazyLoad>
              </div>
              <div className="col-sm-12 col-lg-6 mt-3">
                <LazyLoad>
                  <img
                    className="promotion__image"
                    src={require("../../assets/images/promotion/promotion-3.jpg")}
                    alt="amb-promotion"
                  />
                </LazyLoad>
              </div>
              <div className="col-sm-12 col-lg-6 mt-3">
                <LazyLoad>
                  <img
                    className="promotion__image"
                    src={require("../../assets/images/promotion/promotion-4.jpg")}
                    alt="amb-promotion"
                  />
                </LazyLoad>
              </div>
              <div className="col-sm-12 col-lg-6 mt-3">
                <LazyLoad>
                  <img
                    className="promotion__image"
                    src={require("../../assets/images/promotion/promotion-1.jpg")}
                    alt="amb-promotion"
                  />
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotion;

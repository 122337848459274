import React from "react";
import { Article1 } from "../components";

const ArticleScreen = () => {
  return (
    <>
      <Article1 />
    </>
  );
};

export default ArticleScreen;

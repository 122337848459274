import React from "react";
import "../../App.scss";
import "./style.scss";
import { openSideBar, closeSideBar } from "../../utils/main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose, faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination } from "swiper";

SwiperCore.use([Autoplay]);
const Banner = () => {
  return (
    <section className="banner">
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={true}
        // modules={[Pagination]}
        // navigation={true}
        className="swiper-container"
        spaceBetween={0}
        slidesPerView={1}
      >
        <SwiperSlide>
          <img
            className="swiper-img"
            src={require("./../../assets/images/banner/banner-page-01.png")}
            alt="slide-1"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="swiper-img"
            src={require("./../../assets/images/banner/banner-page-02.png")}
            alt="slide-1"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="swiper-img"
            src={require("./../../assets/images/banner/banner-page-03.png")}
            alt="slide-1"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="swiper-img"
            src={require("./../../assets/images/banner/banner-page-04.png")}
            alt="slide-1"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="swiper-img"
            src={require("./../../assets/images/banner/banner-page-05.png")}
            alt="slide-1"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="swiper-img"
            src={require("./../../assets/images/banner/banner-page-06.png")}
            alt="slide-1"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="swiper-img"
            src={require("./../../assets/images/banner/banner-page-07.png")}
            alt="slide-1"
          />
        </SwiperSlide>

        {/* <SwiperSlide>
          {" "}
          <img
            className="swiper-img"
            src={require("./../../assets/images/Slide-2-1100x450.jpg")}
            alt="slide-1"
          />
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <img
            className="swiper-img"
            src={require("./../../assets/images/Slide-3-1100x450.jpg")}
            alt="slide-1"
          />
        </SwiperSlide> */}
      </Swiper>
    </section>
  );
};

export default Banner;

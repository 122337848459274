import React from "react";
import { Article14 } from "../components";

const ArticleScreen14 = () => {
    return (
        <>
            <Article14 />
        </>
    );
};

export default ArticleScreen14;

import React from "react";
import "../../App.scss";
import "./style.scss";
import { openSideBar, closeSideBar } from "../../utils/main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose, faHome } from "@fortawesome/free-solid-svg-icons";
import { faLine } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

let payment = [
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/SCB.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/KBANK.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/KTB.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/BBL.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/GSB.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/BAY.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/BAAC.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/CIMB.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/CITI.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/ICBC.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/KKB.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/LNH.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/SCBT.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/TCR.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/TISGO.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/OSK.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/TTB.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/UOB.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/MIZUHO.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/ISBT.png"),
  },
  {
    name: "BAAC",
    image: require("./../../assets/images/payment-logo/TRUEWALLET.png"),
  },
];

let tag = [
  "ambking+",
  "ambking plus",
  "ambkingplus",
  "slot",
  "slot game",
  "ambking plus slot",
  "slot amkbing plus",
];

const Footer = () => {
  return (
    <section className="footerSection">
      <div className="row footerBox">
        <div className="col-md-6 col-lg-3">
          <div className="footerContentBox">
            <div className="footerContentBox__header">AMBKINGplus</div>
            <div className="footerContentBox__content">
              <p>
                AMBKINGplus เกมคาสิโนมาใหม่ บาคาร่า เสือ-มังกร สล็อต ยิงปลา
                จบได้ในที่เดียว เล่นง่าย
                ใครก็สามารถทำกำไรจากระบบเกมเดิมพันไม่ยาก
                กลายเป็นสิ่งที่กำลังได้รับความนิยมเนื่องจากคนไทยชื่นชอบการเสี่ยงดวงเป็นอย่างมาก
                โดยเฉพาะหวย บาคาร่า สล็อต เสือมังกรและการแทงบอล ออนไลน์
                รวมไปถึงเกมการเดิมพันประเภทอื่นๆ
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-4">
          <div className="footerContentBox">
            <div className="footerContentBox__header">Payment Accepted</div>
            <div className="footerContentBox__content">
              <div className="paymentBox">
                {payment.map((item, key) => (
                  <div className="paymentBox__item" key={key}>
                    <img src={item.image} alt={item.name} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="footerContentBox">
            <div className="footerContentBox__header">TAG</div>
            <div className="footerContentBox__content">
              <div className="badgeBox">
                {tag.map((item, key) => (
                  <div className="badgeBox__item">
                    <a href="#" className="badge badge--dark">
                      {item}
                    </a>
                  </div>
                ))}
                {/* {(() => {
                  const arr = [];
                  for (let i = 0; i < 13; i++) {
                    arr.push(
                      <div className="badgeBox__item">
                        <a href="#" className="badge badge--dark">
                          Pgsoft
                        </a>
                      </div>
                    );
                  }
                  return arr;
                })()} */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-2">
          <div className="footerContentBox">
            <div className="footerContentBox__header text-center">
              ติดต่อ Line
            </div>
            <div className="footerContentBox__content text-center">
              <a
                href="https://line.me/R/ti/p/@303qbzlz"
                target="_blank"
                className="d-block contactText contactText--primary"
              >
                <FontAwesomeIcon icon={faLine}></FontAwesomeIcon> Line :
                @ambkingplus
              </a>
              {/* <a href="#" className="d-block contactText contactText--primary">
                <FontAwesomeIcon icon={faLine}></FontAwesomeIcon> Line :
                @168mlsv
              </a> */}
              <a
                href="#"
                className="d-block contactText contactText--secondary"
              >
                ให้บริการตลอด 24 ชม.
              </a>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="footerContentBox">
            <div className="allRights__logo">
              <img
                src={require("../../assets/images/Logo/logoAMB_plus2.png")}
                alt={"logo"}
              />
            </div>
            <div className="allRights__text">
              2023 © AMBKING+ . All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;

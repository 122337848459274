import { useState } from "react";
import Api from "../../service/Api";
import ApiConfig from "../../service/ApiConfig";
import { useSetRecoilState } from "recoil";
import { mockResGetProvider } from '../../mock/resGetProvider';
import { mockResGameList } from "../../mock/resGameListProvider";
import { providerListState, getGameListByProviderState } from "../providerState";

export { ProviderAction };

const ProviderAction = () => {
 
  const api = Api();

  const setProviderList = useSetRecoilState(providerListState);
  const setGameListByProvider = useSetRecoilState(getGameListByProviderState)

  return {
    getProviderList,
    getGameListByProvider
  };


  async function getProviderList(){
    const resGetProvider = await api.post(ApiConfig.APIs.post.provierList);

    // Real Api 

    // if(resGetProvider.data.code === apiCode.SUSCESS){
    //   setProviderList(resGetProvider.data.data);
    // }
    // else{
    //     console.log("error on getProviderList");
    // }

    // using Mock
    setProviderList([mockResGetProvider.data]);
  } 
  
  
  async function getGameListByProvider(){
    // call APi
        // using Mock
        setGameListByProvider([mockResGameList.data]);
  }
};

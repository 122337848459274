import {atom} from 'recoil';


export const providerListState = atom({
    key: 'providerListState',
    default: []
});

export const showGameState = atom({
    key: 'showGameState',
    default: false
})




export const getGameListByProviderState = atom({
    key: 'getGameListByProviderState',
    default: []
})


export const selectedProviderState = atom({
    key: 'selectedProviderState',
    default: {}
})
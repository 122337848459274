import React from "react";
import { Article3 } from "../components";

const ArticleScreen3 = () => {
  return (
    <>
      <Article3 />
    </>
  );
};

export default ArticleScreen3;

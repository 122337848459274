import React, { useEffect, useState } from "react";
import "../../App.scss";
import "./style.scss";
import { openSideBar, closeSideBar } from "../../utils/main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch, faClose } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { startGameState } from "../../recoils/gameState";
import { flushSync } from "react-dom";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {
  getGameListByProviderState,
  selectedProviderState,
  showGameState,
} from "../../recoils/providerState";
import {
  ProviderAction,
  GameAction,
  AuthenticationAction,
} from "../../recoils/actions";
import { gameListNoAuthState } from "../../recoils/gameState";
import { loginModalState } from "../navbar/loginModalController";
import { authState } from "../../recoils/authState";
import LazyLoad from "react-lazy-load";

const GameSection = () => {
  const [isShowGame, setIsShowGame] = useRecoilState(showGameState);
  const gameAction = GameAction();
  const providerAction = ProviderAction();
  const authenticationAction = AuthenticationAction();
  const gameListNoAuth = useRecoilValue(gameListNoAuthState);
  const gameURI = useRecoilValue(startGameState);
  const [selectedProvider, setSelectedProvider] = useRecoilState(
    selectedProviderState
  );
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const setGameURI = useSetRecoilState(startGameState);

  function handleClose() {
    setGameURI("");
    setShow(false);
    authenticationAction.getProfile();
  }

  const auth = useRecoilValue(authState);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const setShowLoginModal = useSetRecoilState(loginModalState);

  const [searchGame, setSearchGame] = useState("");

  function selectMenuCardProvider(event) {
    let selectedMenu = document.getElementsByClassName("menuBrand__item");
    for (let i = 0; i < selectedMenu.length; i++) {
      selectedMenu[i].className = selectedMenu[i].className.replace(
        " active",
        ""
      );
    }
    event.currentTarget.className += " active";
    goToGameSection();
  }

  function autoSelectedMenuCardProvider(id) {
    let allCard = document.getElementsByClassName("menuBrand__item");
    for (let i = 0; i < allCard.length; i++) {
      allCard[i].className = allCard[i].className.replace(" active", "");
    }
    let providerCard = document.getElementById(id);
    providerCard.className += " active";
  }

  function goToGameSection() {
    var element = document.getElementById("gameSection");
    var headerOffset = 80;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  function checkFavGame(gameName) {
    setTimeout(() => {
      let favGame = JSON.parse(localStorage.getItem("favGame"));
      if (favGame.find((e) => e.gameName === gameName)) {
        let star = document.getElementById(gameName);
        star.src = require("../../assets/images/favorite/star-activated.webp");
        star.classList.add("star-active");
      } else {
        let allFav = document.getElementsByClassName("star");
        for (let i = 0; i < allFav.length; i++) {
          if (allFav[i].name == gameName) {
            allFav[i].src = require("../../assets/images/favorite/star.webp");
            allFav[i].classList.remove("star-active");
          }
        }
      }
    }, 20);
  }

  useEffect(() => {
    providerAction.getProviderList();
  }, []);

  return (
    <section id="gameSection" className="gameSection">
      <div className="row">
        <div id="providerBox" className={`col ${isShowGame ? "d-none" : ""}`}>
          {gameListNoAuth[0]
            ? gameListNoAuth.map((item, key) =>
                item.image.vertical != "" ? (
                  !item.id ? (
                    <div
                      className="providerBox__item"
                      key={key}
                      onClick={() => {
                        setSelectedProvider(item);
                        setIsShowGame(true);
                        autoSelectedMenuCardProvider(item.provider);
                      }}
                    >
                      <LazyLoad>
                        <img
                          className="provider_image"
                          src={item.image.vertical}
                          alt="brandX"
                          srcset=""
                        />
                      </LazyLoad>
                    </div>
                  ) : auth.loggedIn ? (
                    <div className="game__item">
                      <LazyLoad>
                        <img
                          onClick={() => {
                            if (auth.loggedIn) {
                              gameAction.startGame(
                                item.id,
                                "https://ambkingplus.com",
                                "th",
                                item.provider,
                                item.gameCategory,
                                localStorage.type
                              );
                              handleShow(true);
                            } else {
                              setShowLoginModal(true);
                            }
                          }}
                          className="game__image"
                          src={item.image.vertical}
                          alt={item.gameName}
                        />
                      </LazyLoad>
                      <div
                        className="favoriteBox"
                        onClick={(e) => {
                          let favIcon = e.target;
                          if (favIcon.matches(".star-active")) {
                            let allFav =
                              document.getElementsByClassName("star");
                            for (let i = 0; i < allFav.length; i++) {
                              if (allFav[i].name == item.gameName) {
                                allFav[
                                  i
                                ].src = require("../../assets/images/favorite/star.webp");
                                allFav[i].classList.remove("star-active");
                              }
                            }
                            e.target.src = require("../../assets/images/favorite/star.webp");
                            e.target.classList.remove("star-active");
                          } else {
                            e.target.src = require("../../assets/images/favorite/star-activated.webp");
                            e.target.classList.add("star-active");
                          }

                          const favGame = JSON.parse(
                            localStorage.getItem("favGame")
                          );
                          let addFav = [];
                          if (favGame != null) {
                            addFav = [...favGame];
                          }
                          if (
                            addFav.find((e) => e.gameName === item.gameName) ===
                            undefined
                          ) {
                            addFav.push(item);
                          } else {
                            addFav = addFav.filter(
                              (e) => e.gameName !== item.gameName
                            );
                          }
                          localStorage.setItem(
                            "favGame",
                            JSON.stringify(addFav)
                          );
                        }}
                      >
                        <LazyLoad>
                          <img
                            id={item.gameName}
                            className={`favorite__image star`}
                            name={item.gameName}
                            src={require("../../assets/images/favorite/star-activated.webp")}
                            alt={item.gameName}
                          />
                        </LazyLoad>
                      </div>
                      {checkFavGame(item.gameName)}
                    </div>
                  ) : null
                ) : null
              )
            : null}
        </div>

        <div className={`row ${!isShowGame ? "d-none" : ""}`}>
          <div
            id="menuGameBar"
            className={"col-3 col-sm-3 col-md-2 col-lg-2 col-xl-1 pl-2 px-2"}
          >
            {/* gameSideBar */}
            <div id="menuBrand" className="menuBrand">
              {gameListNoAuth[0]
                ? gameListNoAuth.map((item, key) => (
                    <div
                      className="menuBrand__item"
                      id={item.provider}
                      key={key}
                      onClick={(event) => {
                        setSelectedProvider(item);
                        selectMenuCardProvider(event);
                      }}
                    >
                      <img
                        src={item.logoTransparentURL}
                        alt="brandX"
                        srcset=""
                      />
                    </div>
                  ))
                : null}
            </div>
          </div>

          {/* selectedByEachProvider */}
          <div className="col">
            {/* searchBox */}
            <div className="row searchBox">
              <div className="col-12 d-flex justify-content-between align-items-center">
                {/* <label className="providerName">{selectedProvider.provider}</label> */}
                <div className="providerWrap">
                  {/* <img src={selectedProvider.logoTransparentURL} alt="" /> */}
                  <span>{selectedProvider.providerName}</span>
                  <div
                    className="closeIcon"
                    onClick={() => {
                      setIsShowGame(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
              <div className="col-12 inputBox">
                <input
                  className="input input-search"
                  type="text"
                  placeholder="ค้นหาจากชื่อเกม"
                  onChange={(e) => {
                    setSearchGame(e.target.value);
                  }}
                  value={searchGame}
                />
                <div className="searchIcon">
                  <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </div>
              </div>
            </div>

            <div id="gameListBox">
              {selectedProvider.games
                ? selectedProvider.games.map((item, key) =>
                    searchGame === "" ? (
                      selectedProvider != key ? (
                        <div className="game__item">
                          <img
                            onClick={() => {
                              if (auth.loggedIn) {
                                gameAction.startGame(
                                  item.id,
                                  "https://ambkingplus.com",
                                  "th",
                                  item.provider,
                                  item.gameCategory,
                                  localStorage.type
                                );
                                handleShow(true);
                              } else {
                                setShowLoginModal(true);
                              }
                            }}
                            className="game__image"
                            src={item.image.vertical}
                            alt={item.gameName}
                          />
                          <div
                            className={`favoriteBox ${
                              !auth.loggedIn ? "d-none" : ""
                            }`}
                            onClick={(e) => {
                              let favIcon = e.target;
                              if (favIcon.matches(".star-active")) {
                                let allFav =
                                  document.getElementsByClassName("star");
                                for (let i = 0; i < allFav.length; i++) {
                                  if (allFav[i].name == item.gameName) {
                                    allFav[
                                      i
                                    ].src = require("../../assets/images/favorite/star.webp");
                                    allFav[i].classList.remove("star-active");
                                  }
                                }
                                e.target.src = require("../../assets/images/favorite/star.webp");
                                e.target.classList.remove("star-active");
                              } else {
                                e.target.src = require("../../assets/images/favorite/star-activated.webp");
                                e.target.classList.add("star-active");
                              }
                              const favGame = JSON.parse(
                                localStorage.getItem("favGame")
                              );
                              let addFav = [];
                              if (favGame != null) {
                                addFav = [...favGame];
                              }
                              if (
                                addFav.find(
                                  (e) => e.gameName === item.gameName
                                ) === undefined
                              ) {
                                addFav.push(item);
                              } else {
                                addFav = addFav.filter(
                                  (e) => e.gameName !== item.gameName
                                );
                              }
                              localStorage.setItem(
                                "favGame",
                                JSON.stringify(addFav)
                              );
                            }}
                          >
                            <img
                              id={item.gameName}
                              className={`favorite__image star`}
                              name={item.gameName}
                              src={require("../../assets/images/favorite/star.webp")}
                              alt={item.gameName}
                            />
                          </div>
                          {item.gameName ? checkFavGame(item.gameName) : ""}
                        </div>
                      ) : (
                        // checkFavGame()
                        ""
                      )
                    ) : item.gameName
                        .toLowerCase()
                        .includes(searchGame.toLowerCase()) ? (
                      <div className="game__item">
                        <img
                          onClick={() => {
                            if (auth.loggedIn) {
                              gameAction.startGame(
                                item.id,
                                "https://ambkingplus.com",
                                "th",
                                item.provider,
                                item.gameCategory,
                                localStorage.type
                              );
                            } else {
                              setShowLoginModal(true);
                            }
                          }}
                          className="game__image"
                          src={item.image.vertical}
                          alt={item.gameName}
                        />
                        <div
                          className={`favoriteBox ${
                            !auth.loggedIn ? "d-none" : ""
                          }`}
                          onClick={(e) => {
                            let favIcon = e.target;
                            if (favIcon.matches(".star-active")) {
                              let allFav =
                                document.getElementsByClassName("star");
                              for (let i = 0; i < allFav.length; i++) {
                                if (allFav[i].name == item.gameName) {
                                  allFav[
                                    i
                                  ].src = require("../../assets/images/favorite/star.webp");
                                  allFav[i].classList.remove("star-active");
                                }
                              }
                              e.target.src = require("../../assets/images/favorite/star.webp");
                              e.target.classList.remove("star-active");
                            } else {
                              e.target.src = require("../../assets/images/favorite/star-activated.webp");
                              e.target.classList.add("star-active");
                            }
                            const favGame = JSON.parse(
                              localStorage.getItem("favGame")
                            );
                            let addFav = [];
                            if (favGame != null) {
                              addFav = [...favGame];
                            }
                            if (
                              addFav.find(
                                (e) => e.gameName === item.gameName
                              ) === undefined
                            ) {
                              addFav.push(item);
                            } else {
                              addFav = addFav.filter(
                                (e) => e.gameName !== item.gameName
                              );
                            }
                            localStorage.setItem(
                              "favGame",
                              JSON.stringify(addFav)
                            );
                          }}
                        >
                          <img
                            id={item.gameName}
                            className={`favorite__image star`}
                            name={item.gameName}
                            src={require("../../assets/images/favorite/star.webp")}
                            alt={item.gameName}
                          />
                        </div>
                      </div>
                    ) : null
                  )
                : null}
            </div>
          </div>
        </div>
      </div>

      <Modal
        id="modalGameStart"
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
      >
        <Modal.Body>
          <div className="modalGameStartBox">
            <div onClick={handleClose} className="close">
              ปิด
            </div>
            <div className="gameIframeBox">
              <iframe
                id="gameIframe"
                src={gameURI.uri}
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default GameSection;

import React from "react";
import { Promotion } from "../components";

const PromotionScreen = () => {
  return (
    <>
      <Promotion />
    </>
  );
};

export default PromotionScreen;

import { Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./App.scss";
import { HomePage, PromotionPage, ArticlePage1, ArticlePage2, ArticlePage3, ArticlePage4, ArticlePage5, ArticlePage6, ArticlePage7, ArticlePage8, ArticlePage9, ArticlePage10, ArticlePage11, ArticlePage12, ArticlePage13, ArticlePage14, } from "./screens";
import { BottomNavbar, Navbar } from "./components";

function App() {
  return (
    <div className="main">
      <div className="mainBG"></div>
      {/* <div className="container"></div> */}
      <div>
        <RecoilRoot>
          <Navbar />
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/Promotion" element={<PromotionPage />} />
            <Route path="/article/โปรโมชั่น-เอเอ็มบีคิง-เล่นง่าย-แตกบ่อยทั้งสมาชิกใหม่และสมาชิกเก่า" element={<ArticlePage1 />} />
            <Route path="/article/พลัสเอเอ็มบีคิง-ผู้ให้บริการ-ambking-เกมเดิมพันออนไลน์ยุคใหม่ทันสมัยที่สุด" element={<ArticlePage2 />} />
            <Route path="/article/ทดลองเล่น-ambking-ฟรีไม่เสียเดิมพันกับเกมออนไลน์เล่นได้เงินจริง" element={<ArticlePage3 />} />
            <Route path="/article/สมัครสมาชิก-AMBking-เล่นฟรี-โปรโมชั่นสมาชิกใหม่และเก่า" element={<ArticlePage4 />} />
            <Route path="/article/แหล่งรวมเกมสล็อต-ambking-slot-ทางเข้า-ambkingplus-เล่นได้ทุกเวลา" element={<ArticlePage5 />} />
            <Route path="/article/รวมบาคาร่า-ambkingplus-เกมคาสิโนที่มากที่สุด-เล่นง่ายออนไลน์-24-ชั่วโมง" element={<ArticlePage6 />} />
            <Route path="/article/แทงบอล-ambking-ค่าน้ำถูกที่สุด-แทงง่ายคู่ฟุตบอลเยอะที่สุด" element={<ArticlePage7 />} />
            <Route path="/article/เกมยิงปลา-ambking-แตกบ่อย-เล่นง่าย-โปรโมชั่นเพียบ" element={<ArticlePage8 />} />
            <Route path="/article/ทางเข้า-AMBKING-ที่เข้าถึงง่ายสมัครครั้งเดียวเล่นได้ตลอดไป" element={<ArticlePage9 />} />
            <Route path="/article/โปรโมชั่นดีดี-ทดลองเล่น-เอเอ็มบีคิง-เกมคาสิโนออนไลน์มากมาย" element={<ArticlePage10 />} />
            <Route path="/article/สมัครสมาชิก-ambkingplus-มีเกมให้เลือกเล่นเพียบ-เล่นได้ไม่อั้น" element={<ArticlePage11 />} />
            <Route path="/article/คาสิโนออนไลน์-ambking-auto-ฝาก-ถอนเงินอัจฉริยะ-รวดเร็ว-24-ชั่วโมง" element={<ArticlePage12 />} />
            <Route path="/article/เกมคาสิโนออนไลน์-Ambking-demo-แหล่งรวมเกมเดิมพันมากมาย" element={<ArticlePage13 />} />
            <Route path="/article/ABMKING-ครบจบเว็บเดียวสุดคุ้มกับเกมบาคาร่าเกมเดิมพันทุกชนิด" element={<ArticlePage14 />} />
          </Routes>
          {/* <BottomNavbar /> */}
        </RecoilRoot>
      </div>
    </div>
  );
}

export default App;

import React from "react";
import { Article8 } from "../components";

const ArticleScreen8 = () => {
    return (
        <>
            <Article8 />
        </>
    );
};

export default ArticleScreen8;

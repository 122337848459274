export const setCookie = (cname, cvalue) => {
        var date = new Date();
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        var expires = "; expires=" + date.toGMTString();
        document.cookie = cname + "=" + JSON.stringify(cvalue) + expires + "; path=/";
    }

export const getCookie = (cname) => {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var _i9 = 0; _i9 < ca.length; _i9++) {
            var c = ca[_i9];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

export const expireAllCookies = (name, paths) => {
        var expires = new Date(0).toUTCString(); // expire null-path cookies as well
        document.cookie = name + "=; expires=" + expires;
        for (var i = 0, l = paths.length; i < l; i++) {
            document.cookie = name + "=; path=" + paths[i] + "; expires=" + expires;
        }
    }

import axios from "axios";
import { useRecoilValue } from "recoil";
import { headerState } from "../recoils";
import { authState } from "../recoils/authState";
import ApiConfig from "./ApiConfig";

const Api = ( ) => {
    const headerApi = useRecoilValue(headerState);
    const auth = useRecoilValue(authState);
    
    const ApiInstances = axios.create({
        baseURL: ApiConfig.APIs.baseURL,
        headers: {
            ...headerApi
        },
        timeout: ApiConfig.APIs.timeout
    });
        

    const handleError = (error) =>{
        return 'error Handled' + error;
    }

    ApiInstances.interceptors.request.use( async function(config) {
        const {headers} = config;
        // const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        if(auth.loggedIn){
            headers['Authorization'] = auth.token;
        }

        // change method
        // if(userInfo != null){
        //     if(userInfo.loggedIn){
        //         headers['Authorization'] = userInfo.token;
        //      }
        // }
        return config;
    });


    ApiInstances.interceptors.response.use( async function(response){

        return response;
    }, handleError);


    return ApiInstances;

    

};

export default Api;
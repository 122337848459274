export const mockResGameList = {
    "code": 0,
    "msg": "SUCCESS",
    "data": [
        {
            "provider": "PGS",
            "providerName": "PG Soft",
            "gameType": [
                "BUY_FREE_SPINS",
                "JACKPOTS",
                "MEGAWAYS",
                "SLOT"
            ],
            "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/PG-Soft.png",
            "logoMobileURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/PG-Soft.png",
            "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/PGS/PGS_1638519869.png",
            "image": {
                "vertical": "https://ambimgcdn.co/img/sportsbook/assets/ld-images/1000x1200-vertical/slot/pgs-1000x1200.png",
                "horizontal": "https://ambimgcdn.co/img/sportsbook/assets/ld-images/1000x460-horizontal/slot/pgs-1000x460.png",
                "banner": "https://ambimgcdn.co/img/sportsbook/assets/ld-images/1040x1040-banner/slot/pgs-1040x1040.png"
            },
            "games": [
                {
                    "id": "63bfd5c622b9e6f28b091ce1",
                    "provider": "PGS",
                    "gameName": "Fortune Rabbit",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/game/PGS/L/PGS_Fortune%20Rabbit_1673516509.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/game/PGS/S/PGS_Fortune%20Rabbit_1673516521.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "63b114809de1192ec5790dcc",
                    "provider": "PGS",
                    "gameName": "Midas Fortune",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/game/PGS/L/PGS_Midas%20Fortune_1672672347.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/game/PGS/S/PGS_Midas%20Fortune_1672672356.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "6395de70f7320aee2573ae32",
                    "provider": "PGS",
                    "gameName": "Asgardian Rising",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/game/PGS/L/PGS_Asgardian%20Rising_1670766237.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/game/PGS/S/PGS_Asgardian%20Rising_1670766246.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "6370d0b3d3f541a01484de4b",
                    "provider": "PGS",
                    "gameName": "Diner Delights",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/game/PGS/L/PGS_Diner%20Delights_1668337852.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/game/PGS/S/PGS_Diner%20Delights_1668337864.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "6343f98165580ff060a42707",
                    "provider": "PGS",
                    "gameName": "Alchemy Gold",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/game/PGS/L/PGS_Alchemy%20Gold_1665399179.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/game/PGS/S/PGS_Alchemy%20Gold_1665399188.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "631728cd3ddeac8867580258",
                    "provider": "PGS",
                    "gameName": "Totem Wonders",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/game/PGS/L/PGS_Totem%20Wonders_1662462253.jpg",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/game/PGS/S/PGS_Totem%20Wonders_1662462261.jpg",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "630c6b3e35a342efac0ec906",
                    "provider": "PGS",
                    "gameName": "Prosperity Fortune Tree",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/game/PGS/L/PGS_Prosperity%20Fortune%20Tree_1662012898.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/game/PGS/S/PGS_Prosperity%20Fortune%20Tree_1661758324.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "6303327eebafc4fa8272f137",
                    "provider": "PGS",
                    "gameName": "Wild Bounty Showdown",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/game/PGS/L/PGS_Wild%20Bounty%20Showdown_1662012948.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/game/PGS/S/PGS_Wild%20Bounty%20Showdown_1661153941.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "62d6747a419ec630e268816b",
                    "provider": "PGS",
                    "gameName": "Wild Coaster",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/game/PGS/L/PGS_Wild%20Coaster_1658221695.jpg",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/game/PGS/S/PGS_Wild%20Coaster_1658221705.jpg",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "62cd2a6c5476ba8e1061c0a0",
                    "provider": "PGS",
                    "gameName": "Legend of Perseus",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Legend%20of%20Perseus_1657612925.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Legend%20of%20Perseus_1657612914.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "62b2c2d0f5f48ee7f4b25f2a",
                    "provider": "PGS",
                    "gameName": "Speed Winner",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Speed%20Winner_1655882515.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Speed%20Winner_1655883038.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "62a6f343ce8a365c3b00be62",
                    "provider": "PGS",
                    "gameName": "Lucky Piggy",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Lucky%20Piggy_1655108536.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Lucky%20Piggy_1655108547.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "628c6bb3b785c10a9714f687",
                    "provider": "PGS",
                    "gameName": "Win Win Fish Prawn Crab",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Win%20Win%20Fish%20Prawn%20Crab_1653369785.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Win%20Win%20Fish%20Prawn%20Crab_1653369791.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acb1",
                    "provider": "PGS",
                    "gameName": "Bikini Paradise ",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Bikini%20Paradise_1622735327.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Bikini%20Paradise_1622735347.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acaf",
                    "provider": "PGS",
                    "gameName": "Caishen Wins",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Caishen%20Wins_1622735225.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Caishen%20Wins_1622735267.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acb0",
                    "provider": "PGS",
                    "gameName": "Candy Burst ",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Candy%20Burst_1622735304.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Candy%20Burst_1622735288.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9aca3",
                    "provider": "PGS",
                    "gameName": "Captain's Bounty",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Captain's%20Bounty_1622733381.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Captain's%20Bounty_1622733392.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acbe",
                    "provider": "PGS",
                    "gameName": "Circus Delight",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Circus%20Delight_1622708035.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Circus%20Delight_1652183077.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac83",
                    "provider": "PGS",
                    "gameName": "Honey Trap of Diao Chan",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Honey%20Trap%20of%20Diao%20Chan_1622779070.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Honey%20Trap%20of%20Diao%20Chan_1622779117.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9aca0",
                    "provider": "PGS",
                    "gameName": "Double Fortune",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Double%20Fortune_1622732561.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Double%20Fortune_1622732549.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acaa",
                    "provider": "PGS",
                    "gameName": "Dragon Hatch",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Dragon%20Hatch_1622735056.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Dragon%20Hatch_1622735036.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac92",
                    "provider": "PGS",
                    "gameName": "Dragon Legend",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Dragon%20Legend_1622781966.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Dragon%20Legend_1622781989.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9aca8",
                    "provider": "PGS",
                    "gameName": "Dragon Tiger Luck",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Dragon%20Tiger%20Luck_1622734979.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Dragon%20Tiger%20Luck_1622734967.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acb7",
                    "provider": "PGS",
                    "gameName": "Dreams of Macau",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Dreams%20of%20Macau_1622707459.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/super_slot/assets/pgs/games/S/PGS-dreams-of-macau-S.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acb3",
                    "provider": "PGS",
                    "gameName": "Egypt's Book of Mystery",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Egypt's%20Book%20of%20Mystery_1622706585.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/super_slot/assets/pgs/games/S/PGS-egypts-book-mystery-S.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac9c",
                    "provider": "PGS",
                    "gameName": "Emperor's Favour",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Emperor's%20Favour_1622735411.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Emperor's%20Favour_1622735396.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9aca5",
                    "provider": "PGS",
                    "gameName": "Flirting Scholar",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Flirting%20Scholar_1622734685.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Flirting%20Scholar_1622734714.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac84",
                    "provider": "PGS",
                    "gameName": "Fortune Gods",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Fortune%20Gods_1622779179.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Fortune%20Gods_1622779212.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acab",
                    "provider": "PGS",
                    "gameName": "Fortune Mouse",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Fortune%20Mouse_1622735076.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Fortune%20Mouse_1622735093.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acc9",
                    "provider": "PGS",
                    "gameName": "Fortune Ox",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Fortune%20Ox_1622711273.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Fortune%20Ox_1621248011.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac87",
                    "provider": "PGS",
                    "gameName": "Tree of Fortune",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Tree%20of%20Fortune_1622780716.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Tree%20of%20Fortune_1622785201.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acc6",
                    "provider": "PGS",
                    "gameName": "Galactic Gems",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Galactic%20Gems_1621247973.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Galactic%20Gems_1621247914.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acb4",
                    "provider": "PGS",
                    "gameName": "Ganesha Fortune",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Ganesha%20Fortune_1622706711.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/super_slot/assets/pgs/games/S/PGS-ganesha-fortune-S.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac9d",
                    "provider": "PGS",
                    "gameName": "Ganesha Gold",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Ganesha%20Gold_1622732065.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Ganesha%20Gold_1622732092.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acc7",
                    "provider": "PGS",
                    "gameName": "Guardians of Ice and Fire",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Guardians%20of%20Ice%20and%20Fire_1622711177.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Guardians%20of%20Ice%20and%20Fire_1621248025.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac8d",
                    "provider": "PGS",
                    "gameName": "Gem Saviour",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Gem%20Saviour_1622781536.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Gem%20Saviour_1622781335.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acad",
                    "provider": "PGS",
                    "gameName": "Gem Saviour Conquest",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Gem%20Saviour%20Conquest_1622735145.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Gem%20Saviour%20Conquest_1622735155.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac99",
                    "provider": "PGS",
                    "gameName": "Gem Saviour Sword",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Gem%20Saviour%20Sword_1622782773.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Gem%20Saviour%20Sword_1622782796.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acbc",
                    "provider": "PGS",
                    "gameName": "Genie's 3 Wishes",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Genie's%203%20Wishes_1622707853.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Genie%27s%203%20Wishes_1652183145.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac96",
                    "provider": "PGS",
                    "gameName": "Hip Hop Panda",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Hip%20Hop%20Panda_1622782532.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Hip%20Hop%20Panda_1622782556.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac90",
                    "provider": "PGS",
                    "gameName": "Hood vs Wolf",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Hood%20vs%20Wolf_1622781797.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Hood%20vs%20Wolf_1622781819.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac91",
                    "provider": "PGS",
                    "gameName": "Hotpot",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "JACKPOTS",
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Hotpot_1622781900.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Hotpot_1622785349.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acc5",
                    "provider": "PGS",
                    "gameName": "Jack Frost's Winter",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "BUY_FREE_SPINS",
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Jack%20Frost's%20Winter_1622708529.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Jack%20Frost%27s%20Winter_1652183334.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acc3",
                    "provider": "PGS",
                    "gameName": "Jewels of Prosperity",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Jewels%20of%20Prosperity_1622708384.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Jewels%20of%20Prosperity_1652183346.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9aca2",
                    "provider": "PGS",
                    "gameName": "Journey to the Wealth",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Journey%20to%20the%20Wealth_1622733233.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Journey%20to%20the%20Wealth_1622733193.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac9f",
                    "provider": "PGS",
                    "gameName": "Jungle Delight",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Jungle%20Delight_1622732329.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Jungle%20Delight_1622732426.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac94",
                    "provider": "PGS",
                    "gameName": "Legend of Hou Yi",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Legend%20of%20Hou%20Yi_1622782262.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Legend%20of%20Hou%20Yi_1622782279.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9aca4",
                    "provider": "PGS",
                    "gameName": "Leprechaun Riches",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Leprechaun%20Riches_1622734636.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Leprechaun%20Riches_1622733914.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acc4",
                    "provider": "PGS",
                    "gameName": "Lucky Neko",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Lucky%20Neko_1622708447.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Lucky%20Neko_1652181390.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9aca9",
                    "provider": "PGS",
                    "gameName": "Mahjong Ways",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Mahjong%20Ways_1622735004.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Mahjong%20Ways_1622735012.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acb2",
                    "provider": "PGS",
                    "gameName": "Mahjong Ways 2",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Mahjong%20Ways%202_1622706467.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/super_slot/assets/pgs/games/S/PGS-mahjong-ways2-S.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac88",
                    "provider": "PGS",
                    "gameName": "Medusa ",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Medusa_1622780905.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Medusa_1622780934.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac86",
                    "provider": "PGS",
                    "gameName": "Medusa II",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Medusa%20II_1622780487.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Medusa%20II_1622780565.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac93",
                    "provider": "PGS",
                    "gameName": "Mr. Hallow-Win",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Mr.%20Hallow-Win_1622782167.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Mr.%20Hallow-Win_1622785407.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9aca7",
                    "provider": "PGS",
                    "gameName": "Muay Thai Champion",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Muay%20Thai%20Champion_1622734940.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Muay%20Thai%20Champion_1622734949.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9aca6",
                    "provider": "PGS",
                    "gameName": "Ninja vs Samurai",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Ninja%20vs%20Samurai_1622734913.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Ninja%20vs%20Samurai_1622734901.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acc8",
                    "provider": "PGS",
                    "gameName": "Opera Dynasty",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Opera%20Dynasty_1622711234.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Opera%20Dynasty_1621248041.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acb6",
                    "provider": "PGS",
                    "gameName": "Phoenix Rises",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Phoenix%20Rises_1622707387.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Phoenix%20Rises_1652181623.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac8b",
                    "provider": "PGS",
                    "gameName": "Plushie Frenzy",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Plushie%20Frenzy_1622781008.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Plushie%20Frenzy_1622781035.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac95",
                    "provider": "PGS",
                    "gameName": "Prosperity Lion",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Prosperity%20Lion_1622782396.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Prosperity%20Lion_1622782418.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acc2",
                    "provider": "PGS",
                    "gameName": "Queen of Bounty ",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Queen%20of%20Bounty_1622708310.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Queen%20of%20Bounty_1652181713.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acac",
                    "provider": "PGS",
                    "gameName": "Reel Love",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Reel%20Love_1622735121.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Reel%20Love_1622735109.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac97",
                    "provider": "PGS",
                    "gameName": "Santa's Gift Rush",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Santa's%20Gift%20Rush_1622782635.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Santa's%20Gift%20Rush_1622782655.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acc0",
                    "provider": "PGS",
                    "gameName": "Secret of Cleopatra",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Secret%20of%20Cleopatra_1622708158.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Secret%20of%20Cleopatra_1622708192.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acae",
                    "provider": "PGS",
                    "gameName": "Shaolin Soccer",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Shaolin%20Soccer_1622735192.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Shaolin%20Soccer_1622735179.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acbf",
                    "provider": "PGS",
                    "gameName": "Thai River Wonders",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Thai%20River%20Wonders_1622708110.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Thai%20River%20Wonders_1652181906.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9aca1",
                    "provider": "PGS",
                    "gameName": "The Great Icescape",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_The%20Great%20Icescape_1622733126.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_The%20Great%20Icescape_1622733158.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac9e",
                    "provider": "PGS",
                    "gameName": "Three Monkeys ",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Three%20Monkeys_1622732150.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Three%20Monkeys_1622732131.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acbd",
                    "provider": "PGS",
                    "gameName": "Treasures of Aztec",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Treasures%20of%20Aztec_1622707905.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Treasures%20of%20Aztec_1652181983.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acc1",
                    "provider": "PGS",
                    "gameName": "Vampire's Charm",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Vampire's%20Charm_1622708233.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Vampire%27s%20Charm_1652182165.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9acba",
                    "provider": "PGS",
                    "gameName": "Wild Fireworks",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Wild%20Fireworks_1622707704.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Wild%20Fireworks_1652182236.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac85",
                    "provider": "PGS",
                    "gameName": "Win Win Won",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Win%20Win%20Won_1622780223.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Win%20Win%20Won_1622780254.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac8c",
                    "provider": "PGS",
                    "gameName": "Wizdom Wonders",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Wizdom%20Wonders_1622781169.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Wizdom%20Wonders_1622781246.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f512720",
                    "provider": "PGS",
                    "gameName": "Ways of the Qilin",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Ways%20of%20the%20Qilin_1638954401.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Ways%20of%20the%20Qilin_1652182192.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f512721",
                    "provider": "PGS",
                    "gameName": "Heist Stakes",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Heist%20Stakes_1638954515.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Heist%20Stakes_1652183187.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f512722",
                    "provider": "PGS",
                    "gameName": "Rise of Apollo",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Rise%20of%20Apollo_1638954631.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Rise%20of%20Apollo_1652181746.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f512723",
                    "provider": "PGS",
                    "gameName": "Sushi Oishi",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Sushi%20Oishi_1638954651.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Sushi%20Oishi_1652181778.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f512724",
                    "provider": "PGS",
                    "gameName": "Jurassic Kingdom",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Jurassic%20Kingdom_1638954674.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Jurassic%20Kingdom_1652183274.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f512725",
                    "provider": "PGS",
                    "gameName": "Mermaid Riches",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Mermaid%20Riches_1638954698.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Mermaid%20Riches_1638955127.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f512726",
                    "provider": "PGS",
                    "gameName": "Groundhog Harvest",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Groundhog%20Harvest_1638954723.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Groundhog%20Harvest_1638955156.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f512727",
                    "provider": "PGS",
                    "gameName": "Raider Jane's Crypt of Fortune",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Raider%20Jane%27s%20Crypt%20of%20Fortune_1638954756.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Raider%20Jane%27s%20Crypt%20of%20Fortune_1638955178.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f512728",
                    "provider": "PGS",
                    "gameName": "Supermarket Spree",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Supermarket%20Spree_1638954776.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Supermarket%20Spree_1638955209.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f512729",
                    "provider": "PGS",
                    "gameName": "Buffalo Win",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Buffalo%20Win_1638954801.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Buffalo%20Win_1638955230.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f51272a",
                    "provider": "PGS",
                    "gameName": "Legendary Monkey King",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Legendary%20Monkey%20King_1638954850.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Legendary%20Monkey%20King_1638955273.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f51272b",
                    "provider": "PGS",
                    "gameName": "Spirited Wonders",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Spirited%20Wonders_1638954871.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Spirited%20Wonders_1638955305.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f51272c",
                    "provider": "PGS",
                    "gameName": "Farm Invaders",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Farm%20Invaders_1638954890.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Farm%20Invaders_1638955329.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "61af2980e5d289e90f51272d",
                    "provider": "PGS",
                    "gameName": "Emoji Riches",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Emoji%20Riches_1638954922.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Emoji%20Riches_1638955367.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "60531c5534d88c344ce9ac9b",
                    "provider": "PGS",
                    "gameName": "Symbols of Egypt",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Symbols%20of%20Egypt_1622783598.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Symbols%20of%20Egypt_1652181926.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "609e78ea4a67b994b50d2a62",
                    "provider": "PGS",
                    "gameName": "Bali Vacation",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "BUY_FREE_SPINS",
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Bali%20Vacation_1622965052.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Bali%20Vacation_1622965105.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "609e78ea4a67b994b50d2a65",
                    "provider": "PGS",
                    "gameName": "Candy Bonanza",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Candy%20Bonanza_1622965193.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Candy%20Bonanza_1622965204.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "609e78ea4a67b994b50d2a63",
                    "provider": "PGS",
                    "gameName": "Crypto Gold",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "BUY_FREE_SPINS",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Crypto%20Gold_1622965128.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Crypto%20Gold_1622965138.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "609e78ea4a67b994b50d2a64",
                    "provider": "PGS",
                    "gameName": "Majestic Treasures",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Majestic%20Treasures_1622965164.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Majestic%20Treasures_1622965171.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "609e78ea4a67b994b50d2a66",
                    "provider": "PGS",
                    "gameName": "Wild Bandito",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT",
                        "MEGAWAYS"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Wild%20Bandito_1622965242.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Wild%20Bandito_1622965251.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "6218ca10cc0fd496ed8ae93f",
                    "provider": "PGS",
                    "gameName": "Fortune Tiger",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Fortune%20Tiger_1645792179.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Fortune%20Tiger_1645792187.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "6218ca73cc0fd496ed8ae943",
                    "provider": "PGS",
                    "gameName": "Destiny of Sun & Moon",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Destiny%20of%20Sun%20&%20Moon_1645791975.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Destiny%20of%20Sun%20&%20Moon_1645791982.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "6218ca2dcc0fd496ed8ae940",
                    "provider": "PGS",
                    "gameName": "Mask Carnival",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Mask%20Carnival_1645792221.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Mask%20Carnival_1652181446.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "6218ca5fcc0fd496ed8ae942",
                    "provider": "PGS",
                    "gameName": "Garuda Gems",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Garuda%20Gems_1645791996.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Garuda%20Gems_1645792004.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "6218ca4ccc0fd496ed8ae941",
                    "provider": "PGS",
                    "gameName": "Oriental Prosperity",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Oriental%20Prosperity_1645792751.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Oriental%20Prosperity_1652181500.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "6218c884cc0fd496ed8ae93e",
                    "provider": "PGS",
                    "gameName": "Cocktail Nights",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Cocktail%20Nights_1645792527.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Cocktail%20Nights_1645792443.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "624ebf1d711ab46abc53530a",
                    "provider": "PGS",
                    "gameName": "Butterfly Blossom",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Butterfly%20Blossom_1649327909.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Butterfly%20Blossom_1649327930.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "624ebfccac4e82ccdb2dd3c0",
                    "provider": "PGS",
                    "gameName": "Rooster Rumble",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Rooster%20Rumble_1649328096.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Rooster%20Rumble_1649328121.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "625d462e711ab46abc535314",
                    "provider": "PGS",
                    "gameName": "The Queen’s Banquet",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_The%20Queen%E2%80%99s%20Banquet_1650280015.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/game/PGS/S/PGS_The%20Queen%E2%80%99s%20Banquet_1662519441.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                },
                {
                    "id": "62791f90e9ccc220061c4ad0",
                    "provider": "PGS",
                    "gameName": "Battleground Royale",
                    "gameCategory": "SLOT",
                    "gameType": [
                        "SLOT"
                    ],
                    "image": {
                        "vertical": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/L/PGS_Battleground%20Royale_1652105133.webp",
                        "horizontal": "https://ambimgcdn.co/img/sportsbook/webp%202/sportsbook/game/PGS/S/PGS_Battleground%20Royale_1652105449.webp",
                        "banner": ""
                    },
                    "status": "ACTIVE"
                }
            ]
        }
    ]
}
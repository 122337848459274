import React from "react";
import { Article13 } from "../components";

const ArticleScreen13 = () => {
    return (
        <>
            <Article13 />
        </>
    );
};

export default ArticleScreen13;

import React from "react";
import { Article9 } from "../components";

const ArticleScreen9 = () => {
    return (
        <>
            <Article9 />
        </>
    );
};

export default ArticleScreen9;

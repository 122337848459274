import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { gameListNoAuthState, gameListAuthState } from "../recoils";
import { onLoad } from "../utils/main";
import { AuthenticationAction, GameAction, ProviderAction } from "../recoils/actions";
import {
  Banner,
  CategoryGame,
  GameSection,
  BottomNavbar,
  SeoContent,
  Footer,
  Maintenance
} from "../components";
import { authState } from "../recoils/authState";

const HomePage = () => {
  const authenticationAction = AuthenticationAction();

  const providerAction = ProviderAction();



  useEffect(() => {
    onLoad();
    // authenticationAction.login("agentthbtest01", "12345678");
    // gameAction.getGameListNoAuth('Slot');
  }, []);
  return (
    <>
      <div className="maintenanceBox">
        <Maintenance />
      </div>
      <div className="contentBox">
        {/* <Banner /> */}
        {/* <CategoryGame /> */}
        <GameSection />
        <SeoContent />
        {/* <Footer /> */}
      </div>
      {/* <BottomNavbar /> */}
    </>
  );
};

export default HomePage;

import React, { useState } from "react";
import "../../App.scss";
import "./style.scss";
import { openSideBar, closeSideBar } from "../../utils/main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose, faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginModalState } from "./loginModalController";
import { AuthenticationAction } from "../../recoils/actions";
import { authState } from "../../recoils/authState";
import { profileState } from "../../recoils/profileState";
import { useEffect } from "react";

const Navbar = () => {
  const [show, setShow] = useRecoilState(loginModalState);
  const dataProfile = useRecoilState(profileState);
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [checkedRemeber, setCheckedRember] = useState(false);
  const auth = useRecoilValue(authState);
  const [showRegister, setShowRegister] = useState(false);
  const [showPromotion, setShowPromotion] = useState(false);
  const [isRegister, setIsRegister] = useState(false);

  const authenticationAction = AuthenticationAction();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClosePromotion = () => setShowPromotion(false);
  const handleShowPromotion = () => setShowPromotion(true);

  const [url, setUrl] = useState("");

  function handleRegister() {
    if (!isRegister) {
      authenticationAction.getProfile();
      setIsRegister(false);
    }
    setShowRegister(false);
  }
  function handleShowRegister(breakpoint, url) {
    setUrl(url);
    setFullscreen(breakpoint);
    setShowRegister(true);
  }
  function showModalPromotion() {
    let firstTimeLogin = localStorage.firstTimeLogin;
    if (firstTimeLogin == "true") {
      console.log("firstTimeLogin", firstTimeLogin);
      setShowPromotion(true);
      localStorage.firstTimeLogin = false;
    }
  }

  const [fullscreen, setFullscreen] = useState(true);

  let userProfile = dataProfile[0];

  useEffect(() => {
    localStorage.firstTimeLogin = true;
    if (auth.loggedIn) {
      authenticationAction.getProfile();
      showModalPromotion();
    }
  }, [auth.loggedIn]);

  return (
    <>
      <nav id="navBar" className="navBox fixed">
        <div className="navBox__container header d-flex justify-content-between align-items-center">
          <div className="" style={{ color: "#FFF", textAlign: "center" }}>
            <div className="hamburger">
              <div className="hamburger__box">
                <div
                // className="sideBar__open"
                // id="sideBar__open"
                // onClick={openSideBar}
                >
                  {/* <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
                  <span className="ml-5">MENU</span> */}
                  {!auth.loggedIn ? (
                    <div className="position-relative">
                      {/* <button
                        className="btn btn-primary btn-login"
                        onClick={handleShow}
                      >
                        เข้าสู่ระบบ
                      </button> */}
                      <div className="btn-outer"></div>
                    </div>
                  ) : (
                    <div
                      className=""
                      style={{ color: "#FFF", textAlign: "center" }}
                    >
                      <a href="/">
                        <img
                          className="logo"
                          src={require("../../assets/images/Logo/logoAMB_plus2.png")}
                          alt="logo"
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {auth.loggedIn ? null : (
            <div className="" style={{ color: "#FFF", textAlign: "center" }}>
              <a href="/">
                <img
                  className="logo noAuth"
                  src={require("../../assets/images/Logo/logoAMB_plus2.png")}
                  alt="logo"
                />
              </a>
            </div>
          )}
          <div className="" style={{ color: "#FFF", textAlign: "center" }}>
            <div className="header__action">
              {!auth.loggedIn ? (
                <div className="position-relative">
                  {/* <button
                    className="btn btn-primary btn-register"
                    onClick={() => {
                      setIsRegister(true);
                      handleShowRegister(
                        true,
                        "https://sport.playauto.cloud/register?prefix=QUtQ&recommend=0"
                      );
                    }}
                  >
                    สมัครสมาชิก
                  </button> */}
                  <div className="btn-outer"></div>
                </div>
              ) : null}

              {auth.loggedIn ? (
                <div className="d-flex">
                  <iframe
                    className="l-lide"
                    src={userProfile.autoLink}
                    frameborder="0"
                  ></iframe>
                  <div
                    className="creditBox"
                    onClick={() => {
                      handleShowRegister(
                        true,
                        "https://sport.playauto.cloud/?prefix=QUtQ"
                      );
                    }}
                  >
                    <div className="icon">
                      <img
                        src={require("../../assets/images/navbar/baht.png")}
                        alt=""
                      />
                    </div>
                    <span>
                      {userProfile.balance
                        ? userProfile.balance.toFixed(2)
                        : "0.00"}
                    </span>
                  </div>

                  <div
                    className="usernameBox"
                    onClick={() => {
                      handleShowRegister(
                        true,
                        "https://sport.playauto.cloud/?prefix=QUtQ"
                      );
                    }}
                  >
                    <span>
                      {userProfile.username ? userProfile.username : "username"}
                    </span>
                  </div>

                  {/* <div
                    className="logout"
                    onClick={() => {
                      authenticationAction.logout();
                    }}
                  >
                    <img
                      src={require("../../assets/images/navbar/logout.png")}
                      alt="logout"
                    />
                  </div> */}
                  {/* <button
                    className="btn btn-primary btn-register"
                    onClick={() => {
                      authenticationAction.logout();
                    }}
                  >
                    ออกจากระบบ
                  </button> */}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </nav>

      <div className="menuBar" id="menuBar">
        <div className="overlay" id="overlay"></div>
        <div className="sideBar" id="sideBar">
          <div className="sideBar__header">
            <label className="sideBar__close" id="sideBar__close">
              <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
              <span className="ml-5">CLOSE</span>
            </label>
          </div>
          <div className="sideBar__menuItem">
            <div className="menuItem">
              <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
              <a href="">โปรโมชัน</a>
            </div>
            <div className="menuItem">
              <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
              <a href="">โปรโมชัน</a>
            </div>
            <div className="menuItem">
              <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
              <a href="">ฝากถอน</a>
            </div>
            <div className="menuItem">
              <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
              <a href="">คาสิโน</a>
            </div>
            <div className="menuItem">
              <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
              <a href="">สล็อต</a>
            </div>
            <div className="menuItem">
              <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
              <a href="">กีฬา</a>
            </div>
            <div className="menuItem">
              <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
              <a href="">หวย</a>
            </div>
            <div className="menuItem">
              <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
              <a href="">ติดต่อเรา</a>
            </div>
            <div className="cover-bar"></div>
          </div>
          {/* <div className="sideBar__footer">
            <button 
            className="btn btn-primary" style={{ marginRight: 10 }}
            >
              เข้าสู่ระบบ
            </button>
            <button className="btn btn-primary">สมัครสมาชิก</button>
          </div> */}
        </div>
      </div>

      {/* Login Modal */}
      <Modal id="modalLogin" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modalLogin">
            <div className="modalLogin__header" onClick={handleClose}>
              <div className="header__logo">
                <img
                  src={require("../../assets/images/Logo/logoAMB_plus2.png")}
                  alt="logo"
                />
              </div>
              <div className="header__text">
                <label>เข้าสู่ระบบ</label>
              </div>
            </div>
            <div className="modalLogin__body">
              <div className="form-group">
                {/* <input className="effect-1" type="text" placeholder="เบอร์โทรศัพท์" />
              <span className="focus-border"></span> */}
                <label className="mb-1 text-white">เบอร์โทรศัพท์</label>
                <input
                  className="input-login"
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setMobileNo(e.target.value);
                  }}
                  value={mobileNo}
                />
              </div>

              <div className="form-group">
                <label className="mb-1 text-white">รหัสผ่าน</label>
                <input
                  className="input-login"
                  type="password"
                  placeholder=""
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </div>
            </div>
            <div className="modalLogin__subBody">
              {/* <label className="checkbox path">
                <input type="checkbox" checked />
                <svg viewBox="0 0 21 21">
                  <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                </svg>
              </label> */}

              <label className="checkbox bounce d-flex">
                <input
                  type="checkbox"
                  defaultValue={checkedRemeber}
                  onChange={() => {
                    setCheckedRember(!checkedRemeber);
                  }}
                />
                <svg viewBox="0 0 21 21">
                  <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                </svg>
                <label className="checkbox__name">จดจำบัญชีของฉัน</label>
              </label>

              {/* <label className="forgotPass__text">ลืมรหัสผ่าน</label> */}
            </div>
            <div className="modalLogin__footer">
              <button
                className="btn btn-login"
                onClick={() => {
                  authenticationAction.login(
                    mobileNo,
                    password
                    // checkedRemeber
                  );
                }}
              >
                ล็อคอิน
              </button>
              {/* <div className="text-center">
                <label className="register__text">สมัครมาชิก</label>
              </div> */}
            </div>
            <div className="noAccBox">
              <label>หรือยังไม่มีบัญชี </label>
              <label
                className="highlight"
                onClick={() => {
                  setIsRegister(true);
                  handleShowRegister(
                    true,
                    "https://sport.playauto.cloud/register?prefix=QUtQ&recommend=0"
                  );
                }}
              >
                สมัครสมาชิก
              </label>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        id="modalGameStart"
        show={showRegister}
        fullscreen={fullscreen}
        onHide={() => setShowRegister(false)}
      >
        <Modal.Body>
          <div className="modalGameStartBox">
            <div onClick={handleRegister} className="close">
              ปิด
            </div>
            <div className="gameIframeBox">
              <iframe id="gameIframe" src={url} frameborder="0"></iframe>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        id="highlightPromotion"
        show={showPromotion}
        onHide={handleClosePromotion}
        centered
      >
        <Modal.Body className="p-0">
          <div className="highlightPromoBox">
            <div onClick={handleClosePromotion} className="close">
              ปิด
            </div>
            <img
              className="promoImg"
              src={require("../../assets/images/promotion/highlight.jpg")}
              alt="highlight"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;

export const mappingGameType = (type) => {
  if (type === "SLOT") {
    return "Slot";
  } else if (type === "FISH" || type === "Fish") {
    return "Fish";
  } else if (type === "CASINO" || type === "Casino") {
    return "Casino";
  } else if (type === "CARD" || type === "Card") {
    return "Card";
  } else if (type === "KENO" || type === "Keno") {
    return "Keno";
  } else if (type === "POKER" || type === "Poker") {
    return "Poker";
  } else if (type === "TRADE" || type === "Trade") {
    return "Trade";
  }
};

import Swal from "sweetalert2";

export const authModal = async (title, text, icon, atr) => {

    if(icon === 'success'){
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            showConfirmButton: false,
            timer: atr,
        });
    }
    else{
        if (typeof atr === "string"){
            Swal.fire({
                title: title,
                text: text,
                icon: icon,
                confirmButtonText: atr,
            });
        }
        else{
            Swal.fire({
                title: title,
                text: text,
                icon: icon,
                showConfirmButton: false,
                timer: atr,
            });
        }
       
    }
 
};
import {atom} from 'recoil';


export const gameListNoAuthState = atom({
    key: 'gameListNoAuthState',
    default: {
        default:[]
    }
});

export const gameListAuthState = atom({
    key: 'gameListAuthState',
    default: {
        data: [],
        gameType: []
    }
});



export const gameListDataState = atom({
    key: 'gameListDataState',
    default: []
});


export const startGameState = atom({
    key: 'startGameState',
    default: {}
});

export const urlRedirectState = atom({
    key: 'urlRedirectState',
    default: ''
})


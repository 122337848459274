import React from "react";
import { Article5 } from "../components";

const ArticleScreen5 = () => {
    return (
        <>
            <Article5 />
        </>
    );
};

export default ArticleScreen5;

import React from "react";
import "../../App.scss";
import "./style.scss";
import LazyLoad from "react-lazy-load";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import video from '../../assets/vdo/night-sky.mp4'


const Maintenance = () => {
  return (
    <div className="maintenanceBox">
      <video id="backgroundVideo" autoPlay loop playsInline muted>
        <source src={video} />
      </video>
      <div className="site-wrapper">
        <div className="site-wrapper-inner">
          <div className="cover-container">
            <div className="masthead clearfix">
              <div className="inner">
                {/* <h3 className="masthead-brand">Oops!</h3> */}
                <nav className="nav nav-masthead">
                  <a className="nav-link nav-social" href="#" title="Facebook"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                  <a className="nav-link nav-social" href="#" title="Twitter"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                  <a className="nav-link nav-social" href="#" title="Youtube"><i className="fab fa-youtube" aria-hidden="true"></i></a>
                  <a className="nav-link nav-social" href="#" title="Instagram"><i className="fab fa-instagram" aria-hidden="true"></i></a>
                </nav>
              </div>
            </div>
            <div className="inner cover">
              <h1 className="cover-heading">Maintenance !</h1>
              {/* <div id="timer">
                <div id="days">02<span>Days</span></div>
                <div id="hours">11<span>Hours</span></div>
                <div id="minutes">52<span>Minutes</span></div>
                <div id="seconds">53<span>Seconds</span></div>
              </div> */}
              <p className="lead cover-copy">Sorry for the inconvenience. We’re performing some maintenance at the moment.</p>
            </div>
            <div className="mastfoot">
              <div className="inner">
                {/* <p>© Your Company. Design: <a href="https://templateflip.com/" target="_blank">TemplateFlip</a></p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;



export default {
  APIs: {
    baseURL: "https://ambkingplusservice.com",
    get: {
      getProfile: "/v1/authenticationService/getProfile",
    },
    post: {
      gameListNoAuth: "/v1/gameService/getGameListNoAuth",
      gameListAuth: "/v1/gameService/getGameListAuth",
      getGameRedirect: "/v1/gameService/getGameRedirect",
      startGame: "/v1/gameService/startGame",
      login: "/v1/authenticationService/login",
      provierList: "/v1/providerService/getProviderList",
    },
    timeout: 60000,
  },
};

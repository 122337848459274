import React from "react";
import { Article4 } from "../components";

const ArticleScreen4 = () => {
  return (
    <>
      <Article4 />
    </>
  );
};

export default ArticleScreen4;

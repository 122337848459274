export function onLoad() {
  setTimeout(() => {
    window.location.href = "https://www.slot-duck.com/";
  }, 1500);

  stickyMenuBar();
}

export const openSideBar = () => {
  let menuBar = document.getElementById("menuBar");
  let sideBar = document.getElementById("sideBar");
  let overlay = document.getElementById("overlay");
  menuBar.classList.add("active");
  sideBar.classList.add("active");
  overlay.classList.add("active");
};

export const closeSideBar = () => {
  let menuBar = document.getElementById("menuBar");
  let sideBar = document.getElementById("sideBar");
  let overlay = document.getElementById("overlay");
  menuBar.classList.remove("active");
  sideBar.classList.remove("active");
  overlay.classList.remove("active");
};

function elementsOverlap() {
  const el1 = document.getElementById("navBar");
  const el2 = document.getElementById("gameSection");

  const domRect1 = el1.getBoundingClientRect();
  const domRect2 = el2.getBoundingClientRect();

  return !(
    domRect1.top > domRect2.bottom ||
    domRect1.right < domRect2.left ||
    domRect1.bottom < domRect2.top ||
    domRect1.left > domRect2.right
  );
}

function stickyMenuBar() {
  let stikyMenuGameBar = elementsOverlap();
  var menuBar = document.getElementById("menuBrand");

  let seoBox = document.getElementById("seoContent");
  let seoBoxHeight = seoBox.offsetHeight;
  let seoBoxWidth = seoBox.offsetWidth;

  window.onreset = () => {
    stikyMenuGameBar = elementsOverlap();
  };

  window.onscroll = () => {
    stikyMenuGameBar = elementsOverlap();
    if (stikyMenuGameBar == true) {
      menuBar.classList.add("sticky");
    } else {
      menuBar.classList.remove("sticky");
    }

    let bounding = seoBox.getBoundingClientRect();

    if (
      bounding.top >= -seoBoxHeight &&
      bounding.left >= -seoBoxWidth &&
      bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth) +
      seoBoxWidth &&
      bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) +
      seoBoxHeight -
      400
    ) {
      menuBar.classList.add("d-none");
    } else {
      menuBar.classList.remove("d-none");
    }
  };
}

function goTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

function menuSidebar() {
  let closeMenu = document.getElementById("sideBar__close");
  let overlay = document.getElementById("overlay");
  closeMenu.addEventListener("click", closeSideBar);
  overlay.addEventListener("click", closeSideBar);
}

import { atom } from "recoil";
import { expireAllCookies, getCookie } from "../utils/cookie";

const checkFirstAuth = () => {
  let dataUserinfo = getCookie("userInfo");
  let username = "";
  let token = "";
  let loggedIn = false;
  if (dataUserinfo) {
    dataUserinfo = JSON.parse(dataUserinfo);
    let userInfo = JSON.parse(dataUserinfo);
    username = userInfo.username;
    token = userInfo.token;
    loggedIn = true;
  } else if (localStorage.userInfo) {
    let localUserinfo = JSON.parse(localStorage.userInfo);
    username = localUserinfo.username;
    token = localUserinfo.token;
    loggedIn = true;
  } else {
    expireAllCookies("userInfo", ["/"]);
  }
  return { username, token, loggedIn };
};

export const authState = atom({
  key: "authState",
  default: {
    loggedIn: checkFirstAuth().loggedIn,
    username: checkFirstAuth().username,
    token: checkFirstAuth().token,
  },
});

import React from "react";
import { Article11 } from "../components";

const ArticleScreen11 = () => {
    return (
        <>
            <Article11 />
        </>
    );
};

export default ArticleScreen11;

import React from "react";
import { Article2 } from "../components";

const ArticleScreen2 = () => {
  return (
    <>
      <Article2 />
    </>
  );
};

export default ArticleScreen2;

import React from "react";
import "../../App.scss";
import "./style.scss";
import LazyLoad from "react-lazy-load";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";

const Announcement = () => {
  return (
    <div className="annoucementBox">
      <div className="icon">
        <FontAwesomeIcon size="xs" icon={faVolumeHigh} style={{ color: "#ffffff" }} />
      </div>
      <div className="target">
        <div className="target-text">
          AMBKing+ เว็บตรงอันดับ 1 ของประเทศไทย ที่รวมทุกการเดิมพันไว้ที่นี่
          ที่เดียว !!!
        </div>
      </div>
    </div>
  );
};

export default Announcement;

import Api from "../../service/Api";
import ApiConfig from "../../service/ApiConfig";
import { apiCode } from "../../utils/constant";
import { useSetRecoilState } from "recoil";
import { profileState } from "../profileState";
import { authState } from "../authState";
import { loginModalState } from "../../components/navbar/loginModalController";
import { setCookie, expireAllCookies } from "../../utils/cookie";
import { authModal } from "../../utils/authModal";

export { AuthenticationAction };

const AuthenticationAction = () => {
  const api = Api();
  const setProfile = useSetRecoilState(profileState);
  const setAuth = useSetRecoilState(authState);
  const setShowLoginModal = useSetRecoilState(loginModalState);

  return {
    login,
    getProfile,
    logout,
  };

  async function login(username, password, showModal) {
    showModal = showModal ? true : false;
    const resLogin = await api.post(ApiConfig.APIs.post.login, {
      username: username,
      password: password,
    });
    if (resLogin.data.code === apiCode.SUSCESS) {
      const userInfo = {
        username: username,
        password: password,
        token: resLogin.data.data.token,
      };
      // if (remember) {
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      localStorage.firstTimeLogin = true;
      // }
      // else {
      //     setCookie("userInfo", JSON.stringify(userInfo))
      // }
      setAuth({
        loggedIn: true,
        username: username,
        token: resLogin.data.data.token,
      });
      setShowLoginModal(false);
      if (showModal == true) {
        authModal("สำเร็จ!", "เข้าสู้ระบบสำเร็จ", "success", 1500);
      }
    } else {
      authModal("เกิดข้อผิดพลาด!", "บัญชีผู้ใช้ไม่อยู่ในระบบ", "error", "OK");
    }
  }

  //Required Authentication
  async function getProfile() {
    const resGetProfile = await api.get(ApiConfig.APIs.get.getProfile);
    if (resGetProfile.data.code === apiCode.SUSCESS) {
      setProfile(resGetProfile.data.data);
    } else if (resGetProfile.data.code === apiCode.NEEDSIGNOUT) {
      logout(false);
    } else if (resGetProfile.data.code == apiCode.UNAUTHORIZED) {
      let userInfo = JSON.parse(localStorage.userInfo);
      logout(false);
      setTimeout(() => {
        login(userInfo.username, userInfo.password);
      }, 1500);
    } else {
      //do something
    }
  }

  async function logout(isTokenExpire, showModal) {
    showModal = showModal ? true : false;
    localStorage.removeItem("userInfo");
    expireAllCookies("userInfo", ["/"]);
    setAuth({ loggedIn: false });
    if (isTokenExpire) {
      authModal("เกิดข้อผิดพลาด!", "กรุณาล็อคอินใหม่", "error", 1500);
    } else {
      if (showModal == true) {
        await authModal("สำเร็จ!", "ออกจากระบบสำเร็จ", "success", 1500);
      }
    }
  }
};

import React from "react";
import { Article6 } from "../components";

const ArticleScreen6 = () => {
    return (
        <>
            <Article6 />
        </>
    );
};

export default ArticleScreen6;

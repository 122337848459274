import React from "react";
import { Article12 } from "../components";

const ArticleScreen12 = () => {
    return (
        <>
            <Article12 />
        </>
    );
};

export default ArticleScreen12;

export const mockResGetProvider = {
    "code": 0,
    "msg": "SUCCESS",
    "data": {
        "card": [
            {
                "provider": "MPK",
                "providerTier": "vvip",
                "providerName": "Millionare Poker",
                "providerType": "CARD",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/MPK/MPK_1660215146.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/MPK/MPK_1660215152.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "AMBP",
                "providerTier": "exclusive",
                "providerName": "AMB Poker",
                "providerType": "CARD",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/AMBP/AMBP_1622619735.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/AMBP/AMBP_1638519753.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "KMK",
                "providerTier": "vvip",
                "providerName": "King Maker",
                "providerType": "CARD",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/KMK/KMK_1653490753.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/KMK/logo-kingmaker.png",
                "status": "ACTIVE",
                "detailStatus": true
            }
        ],
        "casino": [
            {
                "provider": "BETG",
                "providerTier": "",
                "providerName": "BetGames",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/BETG/BETG_1623420397.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/BETG/BETG_1638520112.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "SAG",
                "providerTier": "",
                "providerName": "SA Gaming",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/SAG/SAG_1622783981.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/SAG/SAG_1638519724.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "GD",
                "providerTier": "",
                "providerName": "Green Dragon",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/gd.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/GD/GD_1638520015.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "PMT",
                "providerTier": "",
                "providerName": "PragmaticPlay",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/PMT/PMT_1622705180.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/PMT/PMT_1638519809.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "YB",
                "providerTier": "",
                "providerName": "Yeebet",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/YB/YB_1661940446.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/YB/YB_1661940449.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "RG",
                "providerTier": "",
                "providerName": "Royal Gaming",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/RG/RG_1669631700.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/RG/RG_1669631703.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "CG",
                "providerTier": "",
                "providerName": "Cherry Gaming",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/CG/CG_1660817874.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/CG/CG_1660817877.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "JL",
                "providerTier": "vvip",
                "providerName": "Jili",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/Jili.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/JL/JL_1638519836.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "PTG",
                "providerTier": "",
                "providerName": "Pretty Gaming",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/PTG/PTG_1622784095.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/PTG/PTG_1638519710.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "WM",
                "providerTier": "",
                "providerName": "WMCasino",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/WM/WM_1662377341.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/WM/WM_1662377343.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "DGM",
                "providerTier": "",
                "providerName": "Dream Gaming",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/DGM/DGM_1622783837.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/DGM/DGM_1638519774.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "XG",
                "providerTier": "",
                "providerName": "Xtreme Gaming",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/XG/XG_1622705332.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/XG/XG_1638519925.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "SEX",
                "providerTier": "",
                "providerName": "AE Sexy",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/SEX/SEX_1622783222.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/SEX/SEX_1638519768.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "EBET",
                "providerTier": "",
                "providerName": "EBET",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/EBET/EBET_1622705426.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/EBET/EBET_1638520028.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "BIGG",
                "providerTier": "",
                "providerName": "Big Gaming",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/BIGG/BIGG_1622705435.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/BIGG/BIGG_1638520094.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "MTV",
                "providerTier": "vip",
                "providerName": "Motivation Gaming",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/MTV/MTV_1666176534.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/MTV/MTV_1666176461.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "AB",
                "providerTier": "",
                "providerName": "All Bet",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook%2Fprovider%2FAB/AB_1623326580.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/AB/AB_1638519797.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "AG",
                "providerTier": "",
                "providerName": "Asia Gaming",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/AG/AG_1623420151.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/AG/AG_1638520021.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "MG",
                "providerTier": "",
                "providerName": "Microgaming",
                "providerType": "CASINO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/MG/MG_1666957433.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/MG/MG_1666957445.png",
                "status": "ACTIVE",
                "detailStatus": true
            }
        ],
        "fish": [
            {
                "provider": "CQ9",
                "providerTier": "exclusive",
                "providerName": "CQ9",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/CQ9.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/CQ9/CQ9_1638519828.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "SPN",
                "providerTier": "vvip",
                "providerName": "Spinix",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/SPN/SPN_1656496701.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/SPN/logo-spinix.webp",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "KAGA",
                "providerTier": "vip",
                "providerName": "KA Gaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/KAGA/KAGA_1625560223.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/KAGA/KAGA_1638520140.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "WMS",
                "providerTier": "vvip",
                "providerName": "WMSlot",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/WMS/WMS_1660212558.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/WMS/WMS_1660212561.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "FK",
                "providerTier": "vip",
                "providerName": "Funky Game",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/FKG/FKG_1627477140.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/FK/FK_1638520190.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "AMBS",
                "providerTier": "exclusive",
                "providerName": "AMBSlot",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/AMBS/AMBS_1662622833.jpg",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/AMBS/ambs-logo.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "ICON",
                "providerTier": "vip",
                "providerName": "AT",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/ICON/ICON_1637226664.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/ICON/ICON_1638519782.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "NJ",
                "providerTier": "exclusive",
                "providerName": "Ninja",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook%2Fprovider%2FNJ/NJ_1623326045.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/NJ/NJ_1638519850.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "JL",
                "providerTier": "vvip",
                "providerName": "Jili",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/Jili.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/JL/JL_1638519836.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "SMP",
                "providerTier": "vvip",
                "providerName": "Simple Play",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/SMP/SMP_1622705342.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/SMP/SMP_1638519893.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "DRG",
                "providerTier": "exclusive",
                "providerName": "Askmebet",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/Askmebet.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/DRG/DRG_1638519741.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "K9",
                "providerTier": "exclusive",
                "providerName": "918kiss",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/K9/K9_1647601534.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/K9/logo-918kiss.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "SPG",
                "providerTier": "vip",
                "providerName": "Spadegaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/SPG/SPG_1669366864.jpg",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/SPG/SPG_1669366868.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "JOK",
                "providerTier": "vvip",
                "providerName": "SlotXO",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/Slotxo.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/JOK/JOK_1638519701.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "AMP",
                "providerTier": "exclusive",
                "providerName": "Askmeplay",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/AMP/AMP_1673852219.webp",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/AMP/AMP_1673852224.webp",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "BB",
                "providerTier": "vip",
                "providerName": "Bolebit",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/BB/BB_1669100330.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/BB/BB_1669100332.png",
                "status": "ACTIVE",
                "detailStatus": true
            }
        ],
        "keno": [
            {
                "provider": "KENO",
                "providerTier": "",
                "providerName": "Keno",
                "providerType": "KENO",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/KENO/KENO_1626421730.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/KENO/KENO_1638520067.png",
                "status": "ACTIVE",
                "detailStatus": true
            }
        ],
        "poker": [
            {
                "provider": "POK",
                "providerTier": "exclusive",
                "providerName": "Iampoker",
                "providerType": "POKER",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/POK/POK_1660214593.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/POK/POK_1660214602.png",
                "status": "ACTIVE",
                "detailStatus": true
            }
        ],
        "slot": [
            {
                "provider": "EVO",
                "providerTier": "vvip",
                "providerName": "Evoplay",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/EVO/EVO_1622705467.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/EVO/EVO_1638520103.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "BMG",
                "providerTier": "vip",
                "providerName": "Booming Game",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/BMG/BMG_1662377562.jpg",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/BMG/BMG_1661774527.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "MGS",
                "providerTier": "exclusive",
                "providerName": "Microgaming Slot",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/MG/MG_1623420256.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/MGS/MGS_1638520236.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "CQ9",
                "providerTier": "exclusive",
                "providerName": "CQ9",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/CQ9.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/CQ9/CQ9_1638519828.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "JOK",
                "providerTier": "vvip",
                "providerName": "SlotXO",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/Slotxo.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/JOK/JOK_1638519701.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "BB",
                "providerTier": "vip",
                "providerName": "Bolebit",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/BB/BB_1669100330.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/BB/BB_1669100332.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "PMTS",
                "providerTier": "vvip",
                "providerName": "PragmaticPlay Slot",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/PMT/PMT_1622705180.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/PMTS/PMTS_1638520250.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "DRG",
                "providerTier": "exclusive",
                "providerName": "Askmebet",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/Askmebet.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/DRG/DRG_1638519741.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "AMEB",
                "providerTier": "vip",
                "providerName": "Ameba",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/AMEBA/AMEBA_1622705353.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/AMEB/AMEB_1638519899.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "WMS",
                "providerTier": "vvip",
                "providerName": "WMSlot",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/WMS/WMS_1660212558.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/WMS/WMS_1660212561.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "RLG",
                "providerTier": "vip",
                "providerName": "Relax Gaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/RLG/RLG_1653481499.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/RLG/RLG_1664273674.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "PGS",
                "providerTier": "vvip",
                "providerName": "PG Soft",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/PG-Soft.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/PGS/PGS_1638519869.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "KMK",
                "providerTier": "vvip",
                "providerName": "King Maker",
                "providerType": "CARD",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/KMK/KMK_1653490753.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/KMK/logo-kingmaker.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "ADP",
                "providerTier": "exclusive",
                "providerName": "Advantplay",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/ADP/ADP_1660211972.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/ADP/logo-advantplay.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "AMP",
                "providerTier": "exclusive",
                "providerName": "Askmeplay",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/AMP/AMP_1673852219.webp",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/AMP/AMP_1673852224.webp",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "K9",
                "providerTier": "exclusive",
                "providerName": "918kiss",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/K9/K9_1647601534.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/K9/logo-918kiss.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "ACE333",
                "providerTier": "exclusive",
                "providerName": "ACE333",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/ACE333/ACE333_1633951778.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/ACE333/ACE333_1638520232.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "L22",
                "providerTier": "vip",
                "providerName": "Live22",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/Live22.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/L22/L22_1638519860.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "NETE",
                "providerTier": "vvip",
                "providerName": "NetEnt",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/NETE/NETE_1653491120.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/assets/Banner_Logo/logo-netent.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "MNP",
                "providerTier": "vip",
                "providerName": "MannaPlay",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/MNP/MNP_1622705449.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/MNP/MNP_1638520098.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "AWS",
                "providerTier": "vip",
                "providerName": "All Way Spin",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/AWS/AWS_1625560176.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/AWS/AWS_1638520155.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "SPG",
                "providerTier": "vip",
                "providerName": "Spadegaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/SPG/SPG_1669366864.jpg",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/SPG/SPG_1669366868.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "HBNR",
                "providerTier": "starter",
                "providerName": "Habanero",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/Habanero.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/HBNR/HBNR_1638519846.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "RTG",
                "providerTier": "vvip",
                "providerName": "Red tiger",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/RTG/RTG_1653491496.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/assets/Banner_Logo/logo-redtiger.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "CTG",
                "providerTier": "vip",
                "providerName": "Creative Gaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/Creative-Gaming.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/CTG/CTG_1638519855.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "KAGA",
                "providerTier": "vip",
                "providerName": "KA Gaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/KAGA/KAGA_1625560223.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/KAGA/KAGA_1638520140.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "FTG",
                "providerTier": "vip",
                "providerName": "FunTa Gaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/FTG/FTG_1626338688.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/FTG/FTG_1638520167.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "SMP",
                "providerTier": "vvip",
                "providerName": "Simple Play",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/SMP/SMP_1622705342.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/SMP/SMP_1638519893.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "YGG",
                "providerTier": "vip",
                "providerName": "YGG",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/YGG/YGG_1622630601.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/YGG/YGG_1638519688.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "UPG",
                "providerTier": "vip",
                "providerName": "Ultimate Play Gaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/UPG/UPG_1628243853.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/UPG/UPG_1638520173.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "FK",
                "providerTier": "vip",
                "providerName": "Funky Game",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/FKG/FKG_1627477140.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/FK/FK_1638520190.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "GPS",
                "providerTier": "vip",
                "providerName": "Gioco Plus",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/GPS/GPS_1630492817.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/GPS/GPS_1638520183.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "ACD",
                "providerTier": "vvip",
                "providerName": "Arcadia Gaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/ACD/ACD_1638432746.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/ACD/ACD_1638520177.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "DG",
                "providerTier": "vvip",
                "providerName": "Dragon Gaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/DG/DG_1660214711.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/assets/Banner_Logo/logo-dragongaming.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "I8",
                "providerTier": "vvip",
                "providerName": "I8",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/I8/I8_1642576792.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/I8/I8_1638520197.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "ICON",
                "providerTier": "vip",
                "providerName": "AT",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/ICON/ICON_1637226664.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/ICON/ICON_1638519782.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "MEG",
                "providerTier": "vvip",
                "providerName": "Mega7",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/MEG/MEG_1661838974.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/MEG/MEG_1661838986.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "BOG",
                "providerTier": "vip",
                "providerName": "Booongo",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/BOG/BOG_1668060559.jpg",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/BOG/BOG_1668060566.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "AMBS",
                "providerTier": "exclusive",
                "providerName": "AMBSlot",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/AMBS/AMBS_1662622833.jpg",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/AMBS/ambs-logo.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "JL",
                "providerTier": "vvip",
                "providerName": "Jili",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/assets/provider/Jili.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/JL/JL_1638519836.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "NJ",
                "providerTier": "exclusive",
                "providerName": "Ninja",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook%2Fprovider%2FNJ/NJ_1623326045.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/NJ/NJ_1638519850.png",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "SPN",
                "providerTier": "vvip",
                "providerName": "Spinix",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/SPN/SPN_1656496701.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/SPN/logo-spinix.webp",
                "status": "ACTIVE",
                "detailStatus": true
            },
            {
                "provider": "WZD",
                "providerTier": "vip",
                "providerName": "Wazdan",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/WZD/WZD_1625560206.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/WZD/WZD_1638520136.png",
                "status": "ACTIVE",
                "detailStatus": false
            },
            {
                "provider": "ODG",
                "providerTier": "exclusive",
                "providerName": "Odin Gaming",
                "providerType": "SLOT",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/ODG/ODG_1668417948.jpg",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/provider/ODG/ODG_1668417951.png",
                "status": "ACTIVE",
                "detailStatus": true
            }
        ],
        "trade": [
            {
                "provider": "AMBT",
                "providerTier": "",
                "providerName": "Askmetrade",
                "providerType": "TRADE",
                "logoURL": "https://ambimgcdn.co/img/sportsbook/provider/AMBT/AMBT_1650957826.png",
                "logoTransparentURL": "https://ambimgcdn.co/img/sportsbook/ambt.png",
                "status": "ACTIVE",
                "detailStatus": true
            }
        ]
    }
}